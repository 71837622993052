import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';

type IStylesParams = {
  cart_textFontStyle: StyleParamType.Font;
  cartItemNameFont: StyleParamType.Font;
  cartItemNameColor: StyleParamType.Color;
  cartItemDiscountNameFont: StyleParamType.Font;
  cartItemDiscountNameColor: StyleParamType.Color;
  cartItemMoreDetailsFont: StyleParamType.Font;
  cartItemMoreDetailsColor: StyleParamType.Color;
  cartHeaderBackgroundColor: StyleParamType.Color;
  cartHeaderDividerColor: StyleParamType.Color;
  cartHeaderDividerWidth: StyleParamType.Number;
  cartHeaderDividerPadding: StyleParamType.Number;
  cartTitleFont: StyleParamType.Font;
  cartTitleColor: StyleParamType.Color;
  cartSubtitleFont: StyleParamType.Font;
  cartSubtitleColor: StyleParamType.Color;
  cartCloseButtonColor: StyleParamType.Color;
  cartItemPriceFont: StyleParamType.Font;
  cartItemPriceColor: StyleParamType.Color;
  cartItemStrikethroughPriceColor: StyleParamType.Color;
  cartItemTotalPriceFont: StyleParamType.Font;
  cartItemTotalPriceColor: StyleParamType.Color;
  cartItemQuantityBackgroundColor: StyleParamType.Color;
  cartItemQuantityBorderColor: StyleParamType.Color;
  cartItemQuantityBorderWidth: StyleParamType.Number;
  cartItemQuantityBorderRadius: StyleParamType.Number;
  cartItemQuantityFont: StyleParamType.Font;
  cartItemQuantityFontColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemDiscountNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemDiscountNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  cartItemMoreDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemMoreDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartHeaderDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartHeaderDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartHeaderDividerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(20),
  },
  cartTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  cartTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartCloseButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemStrikethroughPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4', 1),
  },
  cartItemTotalPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemTotalPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemQuantityBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  cartItemQuantityBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemQuantityBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartItemQuantityBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(0),
  },
  cartItemQuantityFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemQuantityFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
});
